import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChildAssetsModel } from 'src/app/common/core/api-interfaces/child-assets';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntityAssetsService {

  constructor(private http: HttpClient) { }

  // Get Child Assets ----------------------------------
  getChildAssetsList(body: any) {
    const url = environment.server_url + "/tc/v1/assets/list-child-assets";
    return this.http.post<ChildAssetsModel[]>(url, body);
  }

  // Get controlled assets ----------------------------------
  getListControlledAssets(body: any) {
    const url = environment.server_url + "/tc/v1/assets/list-controlled-assets";
    return this.http.post<ChildAssetsModel[]>(url, body);
  }
}
